


















































import { Vue, Component, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { ContractType, CrewMemberModel } from "@/api/generated";
import nameof from "@/utility/nameof";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class CrewMemberTable extends Vue {
  @Prop(Boolean) isCompaniesTable?: boolean;

  private loading = false;
  private crewMembers: Array<CrewMemberModel> = [];
  private search = "";
  private headers: Array<DataTableHeader> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<CrewMemberModel>("fullname") },
    { text: "Rating", value: nameof<CrewMemberModel>("rating") },
    {
      text: "Nationality",
      value: nameof<CrewMemberModel>("countryNationality")
    },
    { text: "Age", value: nameof<CrewMemberModel>("dateOfBirth") },
    {
      text: "Current Vessel",
      value: nameof<CrewMemberModel>("currentVessel")
    },
    {
      text: "Current Vessel RSS",
      value: nameof<CrewMemberModel>("currentVesselRss")
    },
    { text: "Agency", value: nameof<CrewMemberModel>("agencyName") },
    {
      text: "Passport Expiry",
      value: nameof<CrewMemberModel>("passportExpiry")
    },
    {
      text: "Date of Termination",
      value: nameof<CrewMemberModel>("employmentEndDate")
    },
    { text: "Active", value: nameof<CrewMemberModel>("active") }
  ];

  private async created() {
    await this.getCrewMembers();
  }

  private updateNote(item: CrewMemberModel) {
    return item.updateNoteDaysBefore ? "error--text" : "";
  }

  private async getCrewMembers() {
    try {
      this.loading = true;
      const response = await Api.CrewMemberService.apiCrewmemberDiscriminatorGet(this.isCompaniesTable ? ContractType.NUMBER_1 : ContractType.NUMBER_0);
      this.crewMembers = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve crew members");
    } finally {
      this.loading = false;
    }
  }
}
