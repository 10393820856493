
















import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import CrewMemberTable from "@/components/crew-member/CrewMemberTable.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { CrewMemberModel } from "@/api/generated";
import crewMemberOverviewRoutes from "@/router/crewMemberOverview";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component({ components: { CrewMemberTable } })
export default class CrewMemberOverview extends Vue {
  private routes = crewMemberOverviewRoutes;

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }
}
