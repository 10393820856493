import { render, staticRenderFns } from "./LandWorkerOverview.vue?vue&type=template&id=5bc2f1fc&scoped=true&"
import script from "./LandWorkerOverview.vue?vue&type=script&lang=ts&"
export * from "./LandWorkerOverview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bc2f1fc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VContainer,VTab,VTabs})
